//React
import React, { useEffect } from 'react';

// Design
import { makeStyles } from '@mui/styles';
import Alert from '@mui/material/Alert';

// Internal
import { appNotificationBarMessage, appNotificationBarType, appShowNotificationBar, setNotificationBar } from '../../store/app/slice';


// Third-party
import { useSelector, useDispatch } from 'react-redux';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    '& > * + *': {
      // marginTop: theme.spacing(2),
    },
    position: "fixed",
    zIndex: 9999999999
  },
}));

export default function NotificationBar() {
    const dispatch = useDispatch();
    const severity = useSelector(appNotificationBarType);
    const msg = useSelector(appNotificationBarMessage);
    const show = useSelector(appShowNotificationBar);

    // SEVERITIES
    // msg: error - red
    // msg: warning - yellow
    // msg: info - blue
    // msg: success - green
    
    const classes = useStyles();

    useEffect(() => {
      if (show) {
        setTimeout(() => {
          dispatch(setNotificationBar(false));
        }, 5000)
      }
    // eslint-disable-next-line
    }, [show])

    return (
      <>
        {
          show ?
          <div className={classes.root}>
            <Alert severity={severity}>{msg}</Alert>
          </div>
        :
        ''
        }
      </>
    );
}