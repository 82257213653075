// React
import React, { useState } from 'react';

// Design
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Input from '@mui/material/Input';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import LockIcon from '@mui/icons-material/Lock';

// Internal

// Third-party

export default function ChangePasswordDialog({ open, setOpen, handleChangePassword }) {
  const [password1, setPassword1] = useState('');
  const [password2, setPassword2] = useState('');

  const handleClose = () => {
      setOpen(false);
  };

  const handlePassword1 = (event) => {
    setPassword1(event.target.value)
  }

  const handlePassword2 = (event) => {
      setPassword2(event.target.value)
  }


  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Alterar Senha (mínimo 8 dígitos)</DialogTitle>
        <DialogContent
          sx={{
            marginTop: '1rem',
          }}
        >
          <DialogContentText id="alert-dialog-description">
            <FormControl autoComplete="off" sx={{marginTop: '1rem'}}>
                <InputLabel htmlFor="input-with-icon-adornment">Senha</InputLabel>
                    <Input
                        startAdornment={
                          <InputAdornment position="start">
                            <LockIcon />
                          </InputAdornment>
                        }
                        type="password"
                        value={password1}
                        onChange={(e) => handlePassword1(e)}
                    />
            </FormControl>
            <FormControl autoComplete="off" sx={{marginTop: '1rem'}}>
                <InputLabel htmlFor="input-with-icon-adornment">Confirme a Senha</InputLabel>
                    <Input
                        startAdornment={
                          <InputAdornment position="start">
                            <LockIcon />
                          </InputAdornment>
                        }
                        type="password"
                        value={password2}
                        onChange={(e) => handlePassword2(e)}
                    />
            </FormControl>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="secondary">
           Cancelar
          </Button>
          <Button 
            onClick={() => handleChangePassword(password1)} 
            color="primary" 
            autoFocus
            disabled={(!password1 || !password2) || (password1 !== password2) || password1.length < 8 || password2.length < 8}
          >
            Alterar
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}