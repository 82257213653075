import { configureStore, combineReducers, getDefaultMiddleware } from '@reduxjs/toolkit';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { appSlice } from './app/slice.js';
import VERSION from '../version';


const rootReducer = combineReducers({
    app : appSlice.reducer
});

const persistConfig = {
    key: VERSION,
    storage: storage,
    // whitelist: ['auth'],
};

const persistReducers = persistReducer(persistConfig, rootReducer);

const store = configureStore({
    reducer: persistReducers,
    
    middleware: getDefaultMiddleware({
        serializableCheck: false, // e é esse o pulo do gato
    }),
});


export const persistor = persistStore(store);
export default store;