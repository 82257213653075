// React
import React from 'react';

// Design
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';

// Internal


// Third-party
import Picker from 'emoji-picker-react';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
  });
  
export default function EmojiDialog({open, setOpen, setTextToSend, textToSend}) {
    
    const handleClose = () => {
      setOpen(false);
    };

    const onEmojiClick = (event, emojiObject) => {
        setTextToSend(textToSend + emojiObject.emoji)
    };
  
    return (
        <Dialog
          open={open}
          TransitionComponent={Transition}
          keepMounted
          onClose={handleClose}
        >
          <DialogTitle></DialogTitle>
          <DialogContent>
            <DialogContentText>
                <Picker onEmojiClick={onEmojiClick} />
            </DialogContentText>
          </DialogContent>
        </Dialog>
    );
}